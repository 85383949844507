import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { CommonHelper } from "app/service/common-helper";
import { MessageService } from "app/service/message.service";
import { StorageS } from "app/service/storage";
import Swal from "sweetalert2";

@Component({
  selector: "user-cmp",
  moduleId: module.id,
  templateUrl: "user.component.html",
  styleUrls: ["./user.component.css"],
})
export class UserComponent implements OnInit, OnDestroy {
  public AdminData = JSON.parse(localStorage.getItem("AdminData"));
  public ProfileForm: FormGroup;
  public PasswordForm: FormGroup;
  public model: any;
  public baseUrl = "user";
  public hasDialog = false;
  public title: string;
  public showOTP = false;
  public isLoading = false;
  private verifiedPhoneNumber = null;
  public timer = {
    interval: null,
    show: false,
    minutes: "00",
    seconds: "00",
  };
  public resend = {
    show: false,
    count: 0,
  };
  isPasswordVisible= false;
  isconfirmPasswordVisible= false;

  constructor(
    private storageS: StorageS,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.data.subscribe((res) => {
      this.title = res.title;
    });
    this.ProfileForm = new FormGroup({
      Username: new FormControl(this.AdminData.username, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(20),
      ]),
      EmailAddress: new FormControl(this.AdminData.email, [
        Validators.required,
        Validators.email,
        Validators.maxLength(30),
      ]),
      Phone: new FormControl(this.AdminData.phone, [
        Validators.required,
        Validators.minLength(10),
      ]),
      AlternatePhone: new FormControl(this.AdminData.alternate_phone, [
        Validators.minLength(10),
      ]),
      Address: new FormControl(this.AdminData.address, [
        Validators.required,
        Validators.minLength(3),
      ]),
      ZipCode: new FormControl(this.AdminData.zipcode, [
        Validators.required,
        Validators.minLength(6),
      ]),
      AboutMe: new FormControl(this.AdminData.description),
    });

    this.PasswordForm = new FormGroup({
      currentPassword: new FormControl("",  [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(20),
      ]),
      newPassword: new FormControl("", [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(20),
      ]),
    });
  }

  ngOnInit() {
    this.model = this.AdminData;
    this.ProfileForm.get("Phone").valueChanges.subscribe((res) => {
      if (
        `${res}`.length == 10 &&
        this.model.phone != res &&
        this.verifiedPhoneNumber !== res
      ) {
        this.isLoading = true;
        if (this.timer.interval) {
          clearInterval(this.timer.interval);
        }
        this.resend = {
          show: false,
          count: 0,
        };
        this.timer = {
          interval: null,
          show: false,
          minutes: "00",
          seconds: "00",
        };
        this.sendOtp(res);
        
      } else {
        this.showOTP = false;
        if (this.ProfileForm.contains("otp")) {
          this.ProfileForm.removeControl("otp");
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.timer.interval) {
      clearInterval(this.timer.interval);
    }
  }

  verifyOtpValidator() {
    const formControl: AbstractControl = this.ProfileForm?.get("otp");
    if (
      formControl &&
      (formControl.errors === null || formControl.errors.verify)
    ) {
      const value: string | null = this.ProfileForm.get("otp").value;

      if (formControl.hasError("verify")) {
        delete formControl.errors.verify;
      }

      if (value.length == 6) {
        return { verify: true };
      }
      return null;
    } else {
      return null;
    }
  }

  verifyOTP() {
    const otp = this.ProfileForm.get("otp").value;

    if (otp.length == 6) {
      this.isLoading = true;
      this.storageS.verifyOTP("user", this.model.id, otp).subscribe(
        (res) => {
          this.isLoading = false;
          this.verifiedPhoneNumber = this.ProfileForm.get("Phone").value;
          this.showOTP = false;
          if (this.ProfileForm.contains("otp")) {
            this.ProfileForm.removeControl("otp");
          }
        },
        (error) => {
          this.isLoading = false;
          this.verifiedPhoneNumber = null;
          if (error.error.message == "expired") {
            this.ProfileForm.get("otp").setErrors({ expired: true });
            this.resend.show = true;
          } else {
            this.ProfileForm.get("otp").setErrors({ invalid: true });
          }
        }
      );
    }
  }

  sendOtp(phoneNumber, resend = false) {
    this.storageS.triggerOTP("user", this.model.id, phoneNumber).subscribe(
      (res:any) => {
        if (!this.ProfileForm.contains("otp")) {
          this.ProfileForm.addControl(
            "otp",
            new FormControl(null, {
              validators: [
                Validators.required,
                this.verifyOtpValidator.bind(this),
              ],
            })
          );
        }

        const errors = this.ProfileForm.get("Phone").errors;
        if (errors) {
          delete errors.duplicate;
          this.ProfileForm.get("Phone").setErrors(errors);
        }

        this.showOTP = true;
        const otpDate =
          typeof res?.data[0]?.otpDate == "string"
            ? new Date(res?.data[0]?.otpDate).getTime()
            : res?.data[0]?.otpDate;
        this.startOTPTimer(otpDate);
        this.isLoading = false;
        this.ProfileForm.get("otp").valueChanges.subscribe((value) => {
          const errors = this.ProfileForm.get("otp").errors;
          if (errors) {
            delete errors.invalid;
            delete errors.expired;
            this.ProfileForm.get("otp").setErrors(errors);
          }
        });
      },
      (error) => {
        if (error?.error?.data?.duplicate) {
          this.ProfileForm.get("Phone").setErrors({ duplicate: true });
        }
        this.isLoading = false;
      }
    );
  }

  resendOtp() {
    const phoneNumber = this.ProfileForm.get("phone").value;
    this.resend.show = false;
    this.resend.count += 1;
    this.sendOtp(phoneNumber, true);
  }

  startOTPTimer(dateTime = null) {
    this.resend.show = false;
    const OTP_EXPIRY_DURATION = 2 * 60 * 1000; //2minutes
    const startTime = dateTime ? dateTime : Date.now();
    const expiryTime = startTime + OTP_EXPIRY_DURATION;

    this.timer.interval = setInterval(() => {
      const currentTime = Date.now();
      const timeLeft = expiryTime - currentTime;

      if (timeLeft <= 0) {
        this.timer.show = false;
        clearInterval(this.timer.interval);
        if (this.resend.count <= 2) {
          this.resend.show = true;
        }
        // You can add logic to handle expiry here, e.g., disable form submission.
      } else {
        // Format remaining time in MM:SS
        this.timer.show = true;
        this.timer.minutes = `${Math.floor(timeLeft / (60 * 1000))}`;
        this.timer.seconds = `${Math.floor((timeLeft % (60 * 1000)) / 1000)}`;
      }
    }, 1000); // Update every second
  }
  

  async UpdateProfile() {
    this.model.nm = this.ProfileForm.get("Username").value;
    this.model.em = this.ProfileForm.get("EmailAddress").value;
    this.model.ph = this.ProfileForm.get("Phone").value;
    this.model.aph = this.ProfileForm.get("AlternatePhone").value;
    this.model.ad = this.ProfileForm.get("Address").value;
    this.model.zc = this.ProfileForm.get("ZipCode").value;
    this.model.dsc = this.ProfileForm.get("AboutMe").value;

    let response = await CommonHelper.save(this);
    if (response) {
      let adminData = {
        username: this.ProfileForm.get("Username").value,
        email: this.ProfileForm.get("EmailAddress").value,
        phone: this.ProfileForm.get("Phone").value,
        alternate_phone: this.ProfileForm.get("AlternatePhone").value,
        address: this.ProfileForm.get("Address").value,
        zipcode: this.ProfileForm.get("ZipCode").value,
        description: this.ProfileForm.get("AboutMe").value,
        type: this.AdminData.type,
        id: this.AdminData.id,
      };
      localStorage.setItem("AdminData", JSON.stringify(adminData));

      Swal.fire({
        icon: "success",
        title: "Profile updated!",
        text: "Your profile has been updated successfully!",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "OK",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Something went wrong!",
        text: "Your profile was not updated due to some error",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "OK",
      });
    }
  }

  async UpdatePassword() {
    let req = {
      newPassword: this.PasswordForm.get("newPassword").value,
      currentPassword: this.PasswordForm.get("currentPassword").value,
    };
    this.storageS.updatePassword(req, this.AdminData.id).subscribe(
      (res) => {
        Swal.fire({
          icon: "success",
          title: "Password updated!",
          text: "Your password has been updated successfully!",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "OK",
        });

        this.PasswordForm.reset();
      },
      (err) => {
        Swal.fire({
          icon: "error",
          title: "Invalid Current Password",
          text: "Please enter the correct current password!",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "OK",
        });
      }
    );
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleConfirmPasswordVisibility(){
    this.isconfirmPasswordVisible =!this.isconfirmPasswordVisible;
  }
}
