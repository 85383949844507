import {Message}    from '@angular/compiler/src/i18n/i18n_ast';
import {Injectable} from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

const baseURL = 'https://heksh-storage.s3.ap-south-1.amazonaws.com/modules/';

@Injectable({
	            providedIn: 'root'
            })
export class CommonHelper{

  public static attributeSubject = new BehaviorSubject<any>(null);
  public static attributeValues$ = CommonHelper.attributeSubject.asObservable();

	static delete(ctrl, isAdmin=false){

		const deletedIds = [];

		for(const id in ctrl.selectedIds){
			if(ctrl.selectedIds[id]){
				deletedIds.push(ctrl.selectedIds[id]);
				 console.log(deletedIds);
			}
		}
		if(deletedIds.length === 1){

			return ctrl.storageS.delete(deletedIds[0], ctrl.baseUrl).subscribe(
				(response) => {
					console.log(response.message);
					let msg = 'Successfully Deleted';
					ctrl.messageService.add(msg);
					if(ctrl.hasDialog){
						ctrl.closeDialog();
					}
					if(isAdmin || (ctrl.AdminData.type == 1))
					{
					ctrl.fetchAll();
					}
					else{
						ctrl.fetchAllByVendor();
					}
				},
				(error) => {
					console.log(error);
					let msg = 'Error';
					ctrl.messageService.add(msg);
				}
			);
		} else{
			return ctrl.storageS.deleteRows(deletedIds, ctrl.baseUrl).subscribe(
				(response) => {
					console.log(response.message);
					let msg = 'Successfully Deleted';
					ctrl.messageService.add(msg);
					if(isAdmin || (ctrl.AdminData.type == 1))
					{
					ctrl.fetchAll();
					}
					else{
						ctrl.fetchAllByVendor();
					}
				},
				(error) => {
					console.log(error);
					let msg = 'Error';
					ctrl.messageService.add(msg);
				}
			);
		}
	}

	static fetchAll(ctrl){
		return new Promise((resolve, reject)=>{
			ctrl.tableList = [];
			ctrl.storageS.getAll(ctrl.baseUrl).subscribe(
				(response) => {

					ctrl.tableList = response.data;
					ctrl.messageService.add('Successfully Added');
					resolve(true);
				},
				(error) => {

					ctrl.messageService.add('Error');
					resolve(false)
				}
			);
		})
	}

	static fetchByType(ctrl){
		return new Promise((resolve, reject)=>{
			ctrl.tableList = [];
			ctrl.storageS.getByType(ctrl.baseUrl, ctrl.type).subscribe(
				(response) => {
					console.log(response.message);
					ctrl.tableList = response.data;
					ctrl.messageService.add('Successfully Added');
					resolve(true);
				},
				(error) => {
					console.log(error);
					ctrl.messageService.add('Error');
					resolve(false)
				}
			);
		})
	}

	static countForAdmin(ctrl){
		console.log(ctrl.baseUrl);
		return ctrl.storageS.countForAdmin(ctrl.baseUrl).subscribe(
			(response) => {
				console.log(response.message);
				ctrl.DashboardStatistics = response.data;
				ctrl.messageService.add('Successfully Fetched');
			},
			(error) => {
				console.log(error);
				ctrl.messageService.add('Error');
			}
		);
	}

	static countForVendor(ctrl){
		return ctrl.storageS.countForVendor(ctrl.model.id, ctrl.baseUrl).subscribe(
			(response) => {
				console.log(response.data);
				ctrl.DashboardStatistics = response.data;
				ctrl.messageService.add('Successfully Fetched');
			},
			(error) => {
				console.log(error);
				ctrl.messageService.add('Error');
			}
		);
	}

	static save(ctrl, values?){

		const model = values || ctrl.model



		if(model?.id){
			return new Promise((resolve, reject)=>{
				ctrl.storageS.update(model?.id, model, ctrl.baseUrl)
				.subscribe(
					(message: Message) => {

						let msg = 'Successfully Updated';

						ctrl.messageService.add(msg);
						if(ctrl?.hasDialog){
							ctrl.closeDialog();
						}
						resolve(true);
					},
					(error) => {
						console.log(error);
						let msg = 'Error';
						ctrl.messageService.add(msg);
						resolve(false)
					}
				);
			})

		} else{
			return ctrl.storageS.create(model, ctrl.baseUrl).subscribe(
				(message: Message) => {
				console.log(model);
					let msg = 'Successfully Added';
					ctrl.messageService.add(msg);
					return ctrl.closeDialog();
				},
				(error) => {
					console.log(error);
					let msg = 'Error';
					ctrl.messageService.add(msg);
				}
			);
		}
	}

	static login(ctrl){

		return new Promise((resolve, reject)=>{
			ctrl.storageS.login(ctrl.model, ctrl.baseUrl).subscribe(
				(response)=>{
					ctrl.messageService.add('Successfully Added');
					localStorage.setItem('AdminData', JSON.stringify(response))
					resolve(true);
				},
				(error)=>{
					console.log(error);
					resolve(false)
					ctrl.messageService.add('Error');
				}
			)
		})
	}

	static buildServerPath(model){
		if((model.zf !== undefined)){
			if(model.zf && model.zf.length){
				model.zf = JSON.parse(model.zf)
				for(let zf of model.zf){
					zf.url = `${zf.url.includes(baseURL) ? '' : baseURL}${zf.url.includes(zf.nm) ? zf.url : zf.url + zf.nm}`;
					zf.a   = 0;
				}
				model.zf[0].a = 1;
			} else{
				model.zf = [];
			}
		}
	}

	static trimServerPath(model){
		if((model.zf !== undefined)){
			if(model.zf && model.zf.length){
				for(let zf of model.zf){
					zf.url = zf.url.replace(baseURL, '');
					zf.url = zf.url.replace(zf.nm, '');
					delete zf.a;
				}
			} else{
				model.zf = null;
			}
		}
	}

	static update(ctrl, id){

		return new Promise((resolve, reject)=>{

			ctrl.storageS.update(id, ctrl.data, ctrl.baseUrl).subscribe(
				(response)=>{
					resolve(true);
				},
				(error)=>{
					resolve(false)
					ctrl.messageService.add('Error');
				}
			)
		})
	}

	static status_update(ctrl, id){

		return new Promise((resolve, reject)=>{
			console.log(ctrl.data);
			console.log(ctrl.baseUrl);
			ctrl.storageS.status_update(id, ctrl.data, ctrl.baseUrl).subscribe(
				(response)=>{
					resolve(true);
					console.log(response)
					if(ctrl.hasDialog){
						ctrl.closeDialog();
					}
					if(ctrl.AdminData.type == 1)
					{
					ctrl.fetchAll();
					}
					else{
						ctrl.fetchAllByVendor();
					}
				},
				(error)=>{
					resolve(false)
					console.log(error)
					ctrl.messageService.add('Error');
				}
			)
		})
	}


  static getSelectedAtrributes(selAttributeValues: any){

    console.log(selAttributeValues);

    this.attributeSubject.next(selAttributeValues);

    CommonHelper.attributeSubject.next(selAttributeValues);

  }


}
